// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { styled, useMediaQuery, useTheme } from '@mui/material'
import { useTest } from '@hooks/useTest'
import StrapiLink from './Link'
import StrapiImage from './Image'
import SaleFlag from '../../../@rtg2022/components/atoms/SaleFlag/SaleFlag'
import ImageWithSku from './ImageWithSku'
import { splitName } from './CategoryTile'

const ImgWrapper = styled('div')(({ fitContainer }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: fitContainer ? '100%' : 'auto',
}))

const TitleText = styled('div')(({ theme, titleStyles, multiLineTitle }) => ({
  fontSize: titleStyles?.fontSizeXl ?? '16px',
  lineHeight: '33px',
  color: theme.palette.primary.dark,
  fontWeight: 500,
  textAlign: 'left',
  ...(multiLineTitle
    ? { lineHeight: 'normal', marginTop: '8px' }
    : { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }),
  [theme.breakpoints.down('lg')]: {
    fontSize: titleStyles?.fontSizeLg ?? '15px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: titleStyles?.fontSizeMd ?? '13px',
    textAlign: 'left',
  },
}))

const SalesFlagWrapper = styled('div')(({ theme }) => ({
  '& .MuiBox-root': {
    left: '10px',
    top: '10px',
    padding: '5px 10px',
    [theme.breakpoints.down('md')]: {
      left: '5px',
      top: '5px',
      borderRadius: '4px',
      padding: '4px 8px',
      '& .MuiTypography-root': {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },
}))

const StrapiCategoryTileNew = ({
  data,
  sliderTitle,
  titleStyles,
  selectedPill,
  sliderFilterListTitle,
  fitContainer,
  multiLineTitle,
}) => {
  const { generateTestId } = useTest()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const link = data?.ImageWithSku?.ImageWithSku?.Link?.Link || data?.Link?.Link
  const displayBottomLink = data?.DisplayBottomLink && data?.BottomLink?.Link
  const imageWithSku = data?.ImageWithSku
  const title = splitName(data?.Name, data?.NameBreakCharacter, isMobile)
  const analyticsObj = {
    event: sliderFilterListTitle ? 'SliderFilterList tile click' : 'category tile click',
    ...(selectedPill ? { selectedPill } : {}),
    ...(sliderFilterListTitle ? { sliderFilterListTitle } : {}),
    selectedTile: data?.Name || data?.Heading || data?.Title,
    sliderTitle,
  }

  const renderTitle = tileTitle => (
    <>
      <TitleText
        titleStyles={titleStyles}
        multiLineTitle={multiLineTitle}
        data-testid={generateTestId('component', 'category-tile-v2-title')}
      >
        {tileTitle[0]}
      </TitleText>
      {title.length > 1 && (
        <TitleText
          titleStyles={titleStyles}
          style={{ marginTop: '0' }}
          data-testid={generateTestId('component', 'category-tile-v2-title')}
        >
          {tileTitle[1]}
        </TitleText>
      )}
    </>
  )

  return (
    <StrapiLink data={{ ...link }} trackingData={{ ...analyticsObj }} aria-hidden="true" tabIndex="-1" disableUnderline>
      <ImgWrapper data-testid={data?.testId} fitContainer={fitContainer}>
        {data?.OnSale && (
          <SalesFlagWrapper>
            <SaleFlag label="SALE" />
          </SalesFlagWrapper>
        )}
        {imageWithSku && <ImageWithSku data={imageWithSku} />}
        {!imageWithSku && (
          <StrapiImage
            data={{
              desktop: data?.Image,
              alt: data?.Name,
            }}
            noPaddingBottom={fitContainer}
          />
        )}
        {displayBottomLink ? <StrapiLink disableUnderline data={{ ...data?.BottomLink?.Link }} /> : renderTitle(title)}
      </ImgWrapper>
    </StrapiLink>
  )
}

StrapiCategoryTileNew.propTypes = {
  data: PropTypes.object.isRequired,
  titleStyles: PropTypes.shape({
    fontSizeXl: PropTypes.string,
    fontSizeLg: PropTypes.string,
    fontSizeMd: PropTypes.string,
  }),
  sliderTitle: PropTypes.string,
  selectedPill: PropTypes.string,
  sliderFilterListTitle: PropTypes.string,
  fitContainer: PropTypes.bool,
  multiLineTitle: PropTypes.bool,
}

export const StrapiCategoryTileFragment = graphql`
  fragment StrapiCategoryTileV2Fragment on StrapiCategoryTile {
    id
    testId
    __typename
    Title
    Name
    OnSale
    MobileNameBreak
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
    Image {
      ...StrapiImageFragment
    }
    ImageWithSku {
      ...BaseImageWithSkuFragment
    }
    DisplayBottomLink
    BottomLink {
      Link {
        ...StrapiLinkFragment
      }
    }
  }
`

export default StrapiCategoryTileNew
